import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { FormattedNumber } from "react-intl";

import InfoTolleranceField from "./InfoTolleranceField";

import ProductCounter from "./ProductCounter";
import { roundTwoDigit } from "../../utility/utility";

const useStyles = makeStyles((theme) => ({}));

const SummaryProductInCart = (props) => {
  const props_couponTotal = props.couponTotal;
  const props_cartSummary = props.cartSummary;
  const props_amountIncr = props.AmountIncr || 0;
  const props_removeFromCart = props.removeFromCart;
  const props_updateQuantityProduct = props.updateQuantityProduct;
  const props_showPercIncr =
    props.showPercIncr == null || props.showPercIncr == undefined
      ? true
      : props.showPercIncr;
  const props_totalSummary = props.totalSummary || function () {};
  const props_subTotal = props.subTotal || function () {};
  const props_deliveryCost = props.deliveryCost || function () {};
  const props_showTotal =
    props.showTotal == undefined || props.showTotal == null
      ? true
      : props.showTotal;
  const classes = useStyles();
  const theme = useTheme();

  const calculateTotal = () => {
    if (!props_cartSummary.webSpoProductInCart) return;
    var total =
      Math.round(
        (props_cartSummary.webSpoProductInCart.reduce(
          (accumul, prod) => accumul + (prod.amount || 0),
          0
        ) +
          (props_amountIncr || 0) -
          (props_couponTotal || 0)) *
          100
      ) / 100;
    props_totalSummary(total);
    return total;
  };

  const calculateSubTotale = () => {
    var subTotale = roundTwoDigit(
      props_cartSummary.webSpoProductInCart
        .filter(
          (c) =>
            c.idProduct != props.store.AppReducers.SpoShopCfg.articleExpense
        ) // "SP001"
        .reduce((accumul, prod) => accumul + prod.amount, 0)
    );
    props_subTotal(subTotale);
    return subTotale;
  };

  const calculateDeliveryCost = (row) => {
    var cost = roundTwoDigit(
      props_cartSummary.webSpoProductInCart.filter(
        (c) => c.idProduct == row.idProduct
      )[0].amount
    );
    props_deliveryCost(cost);
    return cost;
  };

  const [total, setTotal] = React.useState(0);

  useEffect(() => {
    if (!props_cartSummary) return;
    setTotal(calculateTotal());
  }, [props_cartSummary, props_amountIncr]);

  return (
    <Grid container>
      <Grid xs={12}>
        {props_cartSummary && (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Prodotto</TableCell>
                  <TableCell>Quantità</TableCell>
                  <TableCell align="right">Prezzo</TableCell>
                  <TableCell align="right">Totale</TableCell>
                  {/* <TableCell align="right">Descrizione</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {props_cartSummary.webSpoProductInCart
                  .filter(
                    (c) =>
                      c.idProduct !=
                      props.store.AppReducers.SpoShopCfg.articleExpense
                  ) //"SP001")
                  .map((row) => {
                    var product =
                      row.vwWebSpoProduct || row.vwWebSpoProductView;
                    return (
                      <TableRow key={row.idProduct}>
                        <TableCell align="left">{product.name}</TableCell>
                        <TableCell component="th" scope="row">
                          <div>
                            <ProductCounter
                              allowZero={true}
                              readOnly={!props_updateQuantityProduct}
                              product={product}
                              setCount={(c) => {
                                row.quantity = c;
                              }}
                              count={row.quantity}
                              removeProduct={props_removeFromCart}
                              updateQuantity={props_updateQuantityProduct}
                            ></ProductCounter>
                          </div>
                        </TableCell>

                        <TableCell align="right">
                          <FormattedNumber
                            value={roundTwoDigit(row.price)}
                            style="currency"
                            currency="EUR"
                            currencySign="accounting"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedNumber
                            value={roundTwoDigit(
                              props_cartSummary.webSpoProductInCart.filter(
                                (c) => c.idProduct == row.idProduct
                              )[0].amount
                            )}
                            style="currency"
                            currency="EUR"
                            currencySign="accounting"
                          />
                        </TableCell>
                        {/* <TableCell align="right">
                            {row.vwWebSpoProduct.description}
                          </TableCell> */}
                      </TableRow>
                    );
                  })}
                <div style={{ display: props_showTotal ? null : "none" }}>
                  <TableRow
                    key={"subtotale"}
                    style={{ marginTop: theme.spo.mSm }}
                  >
                    <TableCell component="th" scope="row">
                      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        Subtotale
                      </div>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <FormattedNumber
                          value={calculateSubTotale()}
                          style="currency"
                          currency="EUR"
                          currencySign="accounting"
                        />
                      </div>
                    </TableCell>
                  </TableRow>

                  {props_cartSummary.webSpoProductInCart
                    .filter(
                      (c) =>
                        c.idProduct ==
                        props.store.AppReducers.SpoShopCfg.articleExpense
                    ) //"SP001")
                    .map((row) => {
                      var product =
                        row.vwWebSpoProduct || row.vwWebSpoProductView;
                      return (
                        // spese di consegna
                        <TableRow key={row.idProduct}>
                          <TableCell component="th" scope="row">
                            <div>{product.name}</div>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">
                            <FormattedNumber
                              value={calculateDeliveryCost(row)}
                              style="currency"
                              currency="EUR"
                              currencySign="accounting"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {props_couponTotal > 0 && (
                    <TableRow key={"couponRow"}>
                      {/* coupon */}
                      <TableCell component="th" scope="row">
                        <div>Coupon</div>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        <FormattedNumber
                          value={-props_couponTotal}
                          style="currency"
                          currency="EUR"
                          currencySign="accounting"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {/* incremento prezzo prodotti peso */}
                  {props_amountIncr > 0 && props.store.AppReducers && (
                    //   props.store.AppReducers.SpoShopCfg.percIncrementWeight > 0 &&
                    <TableRow>
                      <TableCell>
                        <InfoTolleranceField
                          showPercIncr={props_showPercIncr}
                          percIncrementWeight={
                            props.store.AppReducers &&
                            props.store.AppReducers.SpoShopCfg &&
                            props.store.AppReducers.SpoShopCfg
                              .percIncrementWeight
                              ? props.store.AppReducers.SpoShopCfg
                                  .percIncrementWeight
                              : 0
                          }
                        ></InfoTolleranceField>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        + {roundTwoDigit(props_amountIncr).toFixed(2)} €
                      </TableCell>
                    </TableRow>
                  )}
                </div>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Grid xs={12}>
        {props_showTotal && props_cartSummary && (
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                <TableRow key={"totale"}>
                  <TableCell>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      Totale pezzi:{" "}
                      {props_cartSummary.webSpoProductInCart
                        .filter(
                          (c) =>
                            c.idProduct !=
                            props.store.AppReducers.SpoShopCfg.articleExpense
                        ) //"SP001")
                        .reduce(
                          (accumul, prod) =>
                            accumul +
                            ((prod.vwWebSpoProduct || prod.vwWebSpoProductView)
                              .unmis &&
                            (
                              prod.vwWebSpoProduct || prod.vwWebSpoProductView
                            ).unmis.toLowerCase() == "kg"
                              ? 1
                              : prod.quantity),
                          0
                        )}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      Totale articoli:{" "}
                      {
                        props_cartSummary.webSpoProductInCart.filter(
                          (c) =>
                            c.idProduct !=
                            props.store.AppReducers.SpoShopCfg.articleExpense //"SP001"
                        ).length
                      }
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      <FormattedNumber
                        value={total}
                        style="currency"
                        currency="EUR"
                        currencySign="accounting"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, {})(SummaryProductInCart);
// export default SummaryProductInCart;
