import React, { useEffect } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { fetchCfgSpo, SetInfoCart } from "../redux/actions";

import { UserManager } from "oidc-client";

import { GET, POST, PUT, DELETE } from "request-helper";

import {
  Button,
  Grid,
  Paper,
  Divider,
  Link,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import { loginConfig } from "../loginConfig";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import { getImageFromStoreWithCode } from "../utility/utility";
import Logo from "../components/Logo";
import DialogAddress from "../components/Address/DialogAddress";
import { parseAddressComponents } from "../components/geocodeAddress";
import DialogWithContent from "../components/DialogWithContent";
import DialogMessages from "../components/DialogMessages";

const useStyles = makeStyles((theme) => ({
  logo: {
    // paddingTop: "80px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 15,
    },
  },
  mainTitle: {
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: "uppercase",
    textAlign: "center",
    textShadow: "2px 2px 4px #000",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  subTitle: {
    fontSize: "20px",
    marginTop: "100px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  paperContainer: {
    backgroundColor: "rgba(255, 255, 255,  0.8)",
    padding: theme.spo.pLg,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spo.pSm,
    },
  },
  divider: {
    marginTop: "105px",
    marginBottom: theme.spo.mMd,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  shoOnMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  colSx: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  colDx: {
    paddingRight: theme.spo.pMd,
    paddingTop: theme.spo.pMd,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      paddingTop: theme.spo.pSm,
    },
  },
}));

const redirectToLoginIS4 = (props) => {
  if (props.match.params.eanGuid)
    loginConfig.extraQueryParams.eanGuid = props.match.params.eanGuid;
  var mgr = new UserManager(loginConfig);
  mgr.signinRedirect();
};

const FreeHome = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [shop, setShop] = React.useState({});
  const [shopsSelect, setShopSelect] = React.useState(null);
  const [haveCartLimit, setHaveCartLimit] = React.useState(null);

  const [flagNoShop, setFlagNoShop] = React.useState(false);

  const [flagAddress, setFlagAddress] = React.useState(null);
  const [geocodedAddress, setGeocodedAddress] = React.useState(null);
  const [address, setAddress] = React.useState({});

  const [messages, setMessages] = React.useState(null);

  const parseAddress = (type, short) => {
    let addressComponents = geocodedAddress[0].address_components;
    return parseAddressComponents(addressComponents, type, short);
  };

  const fetchPv = () => {
    let param = {
      IdAddress: 0,
      IdDeliveryMode: 1,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Store/ListPuntiVenditaSelect",
      null,
      param,
      true
    ).then((data) => {
      setShopSelect(data);
      props.SetInfoCart({
        ...(props.store.CartReducers || {}),
        ...{ puntoVendita: data[0] },
      });
    });
  };

  const fetchPvFromAddress = () => {
    let _address = parseAddress("route", false);
    let _streetNumber = parseAddress("street_number", false);
    if (_streetNumber) _address = _address + ", " + _streetNumber;
    let _locality = parseAddress("administrative_area_level_3", false);
    let _prov = parseAddress("administrative_area_level_2", true);
    let _postalCode = parseAddress("postal_code", false);

    let addressmodel = {
      Id: 0,
      NoteAddress: address.noteAddress,
      Address: _address || address.address,
      City: _locality || address.city,
      PostalCode: _postalCode || address.postalCode,
      Province: _prov || address.province,
      Lat:
        geocodedAddress[0] &&
        geocodedAddress[0].geometry &&
        geocodedAddress[0].geometry.location &&
        geocodedAddress[0].geometry.location.lat,
      Lon:
        geocodedAddress[0] &&
        geocodedAddress[0].geometry &&
        geocodedAddress[0].geometry.location &&
        geocodedAddress[0].geometry.location.lng,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Store/ListPuntiVenditaFromAddressSelect",
      null,
      addressmodel,
      true
    ).then((data) => {
      setShopSelect(data);
    });
  };

  const fetchMessageFreeUser = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Messages/CheckShopMessage",
      null,
      null,
      true
    ).then((data) => {
      setMessages(data);
    });
  };

  const fetchHaveCartLimit = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Store/HaveCartLimit",
      null,
      null,
      true
    ).then((data) => {
      setHaveCartLimit(data);
    });
  };

  const handlerOnClickRitira = () => {
    setCurrentStep(1);
  };

  const goToStorePreview = (shopId) => {
    // props.SetInfoCart({ shopId: shopId, puntoVendita: shopId });
    props.SetInfoCart({ shopId: shopId?.code || shopId, puntoVendita: shopId });
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/ProductShop");
  };

  useEffect(() => {
    if (currentStep != 1) return;
    if (address && geocodedAddress) fetchPvFromAddress();
    else {
      fetchPv();
    }
  }, [currentStep]);

  useEffect(() => {
    if (haveCartLimit === null || haveCartLimit === undefined) return;
    if (haveCartLimit === false) setCurrentStep(1);
  }, [haveCartLimit]);

  useEffect(() => {
    if (shopsSelect == null) return;
    if (shopsSelect.length == 0) {
      setFlagNoShop(true);
      return;
    }
    if (shopsSelect.length == 1) {
      setShop(shopsSelect[0]);
      goToStorePreview(shopsSelect[0]);
    }
  }, [shopsSelect]);

  useEffect(() => {
    if (process.env.REACT_APP_MAINTENANCE == "false") props.fetchCfgSpo();
    if (props.match.params.eanGuid) {
      loginConfig.extraQueryParams.eanGuid = props.match.params.eanGuid;
      var mgr = new UserManager(loginConfig);
      mgr.signinRedirect();
    }
    fetchMessageFreeUser();
    fetchHaveCartLimit();
  }, []);

  return (
    <Layout
      allowAnonymous={true}
      showToolbar={process.env.REACT_APP_MAINTENANCE == "false"}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            minHeight: "80vh",
          }}
        >
          <DialogMessages
            messages={messages}
            requestRegistered={false}
          ></DialogMessages>
          <div
            class="jss95"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0.5,
              zIndex: -1,
              position: "absolute",
              backgroundColor: "#000",
            }}
          ></div>
          <div
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -2,
              position: "absolute",
              background:
                getImageFromStoreWithCode("MainHome", props.store) +
                " center top / cover no-repeat ",
            }}
          ></div>
          <Grid container style={{ height: "100%" }}>
            {/* colonna sx */}
            <Grid item xs="12" md="8" className={classes.colSx}>
              <Grid container justify="center">
                <Grid
                  xs="4"
                  md={3}
                  item
                  className={classes.logo}
                  style={theme.spo.freeHome.logo}
                >
                  <Logo></Logo>
                </Grid>
                <Grid
                  className={classes.shoOnMd}
                  xs={10}
                  item
                  style={{
                    color: "#fff",
                    paddingTop: "60px",
                    paddingLeft: theme.spo.pSm,
                  }}
                >
                  <h3 style={{ fontWeight: 300, fontSize: 24 }}>
                    Il servizio di spesa online ti permette di prenotare la tua
                    spesa direttamente da casa, scegliendo tra consegna a
                    domicilio o ritiro nel punto vendita
                  </h3>
                </Grid>
              </Grid>
            </Grid>
            {/* colonna dx */}
            <Grid item xs="12" md="4">
              <Grid container className={classes.colDx} style={{}}>
                <Grid item xs="12">
                  <Paper className={classes.paperContainer} style={{}}>
                    <h1 className={classes.mainTitle} style={{}}>
                      LA SPESA ONLINE
                    </h1>
                    {process.env.REACT_APP_MAINTENANCE == "false" ? (
                      <>
                        {currentStep == 0 && (
                          <>
                            <h6 className={classes.subTitle} style={{}}>
                              Scegli come ricevere la tua spesa
                            </h6>
                            <div
                              style={{
                                marginTop: theme.spo.mMd,
                                marginBottom: theme.spo.mMd,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ height: "60px" }}
                                onClick={handlerOnClickRitira}
                              >
                                Ritira in negozio
                              </Button>
                            </div>
                            {haveCartLimit && (
                              <div
                                style={{
                                  marginTop: theme.spo.mMd,
                                  marginBottom: theme.spo.mMd,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  style={{ height: "60px" }}
                                  onClick={() => {
                                    setFlagAddress(true);
                                  }}
                                >
                                  {" "}
                                  Consegna a domicilio
                                </Button>
                                <DialogAddress
                                  showDialog={flagAddress}
                                  showNote={false}
                                  hideDialog={() => {
                                    setFlagAddress(false);
                                  }}
                                  title={"Verifica indirizzo di consegna"}
                                  labelNext={"Verifica"}
                                  saveAddress={(address) => {
                                    setFlagAddress(false);
                                    setAddress(address);
                                    setCurrentStep(1);
                                  }}
                                  geocodedAddress={(data) => {
                                    setGeocodedAddress(data);
                                  }}
                                ></DialogAddress>
                              </div>
                            )}
                          </>
                        )}
                        {currentStep == 1 && (
                          <>
                            <h6
                              style={{
                                fontSize: "20px",
                                marginTop: "100px",
                                textAlign: "center",
                              }}
                            >
                              Scegli il punto vendita
                            </h6>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="shop-select-label">
                                Punto vendita
                              </InputLabel>

                              <Select
                                disabled={false}
                                labelId="shop-select-label"
                                id="shop-select"
                                value={shop}
                                onChange={(e) => {
                                  setShop(e.target.value);
                                  goToStorePreview(e.target.value);
                                }}
                              >
                                {shopsSelect &&
                                  shopsSelect.map((c) => {
                                    return (
                                      <MenuItem value={c.code}>
                                        {c.description}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                            <div
                              style={{
                                marginTop: "40px",
                                marginBottom: theme.spo.mMd,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ height: "60px" }}
                                onClick={handlerOnClickRitira}
                              >
                                Vai al negozio
                              </Button>
                            </div>
                            <DialogWithContent
                              key={"dl-privacy"}
                              open={flagNoShop}
                              onClose={() => {
                                setFlagNoShop(false);
                              }}
                              contentText={
                                <div>
                                  <div>
                                    L'indirizzo da te specificato non è coperto
                                    dal servizio di consegna a domicilio
                                  </div>
                                </div>
                              }
                              title="Indirizzo non servito"
                              action={[
                                {
                                  onClick: () => {
                                    setFlagNoShop(false);
                                    setFlagAddress(true);
                                    setCurrentStep(0);
                                  },
                                  label: "Scegli un altro indirizzo",
                                },
                                {
                                  onClick: () => {
                                    setFlagNoShop(false);
                                    fetchPv();
                                  },
                                  label: "Continua con ritiro al punto vendita",
                                },
                              ]}
                            ></DialogWithContent>
                          </>
                        )}
                        <Divider
                          className={classes.divider}
                          style={{}}
                        ></Divider>
                        <Link
                          href="#"
                          onClick={() => {
                            redirectToLoginIS4(props);
                          }}
                        >
                          Già registrato? Accedi
                        </Link>{" "}
                      </>
                    ) : (
                      <>
                        <h2 style={{ textAlign: "center" }}>
                          Sito in manutenzione!
                        </h2>
                        <h3 style={{ textAlign: "center" }}>
                          A breve sarà possibile effettuare operazioni
                        </h3>
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {process.env.REACT_APP_MAINTENANCE == "false" && <Footer></Footer>}
        </Grid>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, {
  fetchCfgSpo,
  SetInfoCart,
})(FreeHome);
