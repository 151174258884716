//mette nello store i dati dell'utente loggato
export const SET_USER_LOGGED = "SET_USER_LOGGED";
//mette nello store eventuali configurazioni prelvate dal server
export const SET_APP_CFG = "SET_APP_CFG";
export const SET_SPO_CFG = "SET_SPO_CFG";
export const SET_SPO_CFG_SHOP = "SET_SPO_CFG_SHOP";
export const SAVE_STATE_APP = "SAVE_STATE_APP";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_INFO_CART = "SET_INFO_CART";
export const SET_TOTAL_CART = "SET_TOTAL_CART";
export const SET_CART_SUMMARY = "SET_CART_SUMMARY";

export const SET_STATUS_USER_SHOP_LIST = "SET_STATUS_USER_SHOP_LIST";

/**vecchia app */
export const MONITOR_SET_FILTER = "MONITOR_SET_FILTER";
export const MONITOR_LIST_CASSE = "MONITOR_LIST_CASSE";

export const SET_CFG_USER_VIEWAPP = "SET_CFG_USER_VIEWAPP";

export const SET_TYPE_MASTER_PAGE = "SET_TYPE_MASTER_PAGE";

export const SET_SALES_MASTER = "SET_SALES_MASTER";
export const SET_SALES_MASTER_RANGE = "SET_SALES_MASTER_RANGE";
export const SET_SALES_MASTER_RANGE_AP = "SET_SALES_MASTER_RANGE_AP";

export const SET_FILTER_MASTER = "SET_FILTER_MASTER";
