import {
  SET_USER_LOGGED,
  SET_APP_CFG,
  SET_LANGUAGE,
  SET_SPO_CFG,
  SET_SPO_CFG_SHOP,
  SAVE_STATE_APP,
  SET_INFO_CART,
  SET_TOTAL_CART,
  SET_CART_SUMMARY,
  SET_STATUS_USER_SHOP_LIST,
} from "./actionTypes";
import { GET } from "request-helper";
import makeFetch from "./makeFetch";

export const SetAppConfigFromServer = (cfg) => ({
  type: SET_APP_CFG,
  payload: cfg,
});

export const SaveStateApp = (stateToSave) => ({
  type: SAVE_STATE_APP,
  payload: stateToSave,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const SetUserLogged = (user) => ({
  type: SET_USER_LOGGED,
  payload: { user },
});

export function fetchCfgSpo(filter) {
  return makeFetch(SET_SPO_CFG, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Config/GetConfigFrontEndApplication",
      null,
      null,
      true
    );
  });
}

export function fetchCfgShopSpo(shopId) {
  return makeFetch(SET_SPO_CFG_SHOP, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Config/GetConfigFrontendShop",
      null,
      {
        shopId,
      },
      true
    );
  });
}

export const SetInfoCart = (infoCart) => ({
  type: SET_INFO_CART,
  payload: { infoCart },
});

export const SetTotalCart = (total) => ({
  type: SET_TOTAL_CART,
  payload: total,
});

export const setCartSummary = (summary) => ({
  type: SET_CART_SUMMARY,
  payload: summary,
});

export const setStatusUserShopList = (status) => ({
  type: SET_STATUS_USER_SHOP_LIST,
  payload: status,
});
