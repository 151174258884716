import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({}));

const LayoutWithHead = (props) => {
  const props_onclickToggle = props.onClickToggle || function () {};
  const props_rowLogoLeft = props.rowLogoLeft;
  const props_rowLogoRight = props.rowLogoRight;
  const props_rowMenuLeft = props.rowMenuLeft;
  const props_rowMenuRight = props.rowMenuRight;
  const props_rowBar = props.rowBar;
  const props_body = props.body;
  const props_allowAnonymous =
    props.allowAnonymous == undefined || props.allowAnonymous == null
      ? false
      : props.allowAnonymous;

  const classes = useStyles();
  const theme = useTheme();

  const [colRowLogo, setColRowLogo] = React.useState({});
  const [logoShow, setLogoShow] = React.useState(false);

  const getSizeOfLogo = (size) => {
    if (!size.square > 2) {
      //rettangolare
      setColRowLogo({
        col1: {
          sm: 4,
        },
        col2: {
          sm: 8,
        },
      });
    } else {
      //quadrata
      setColRowLogo({
        col1: {
          xs: 4,
          sm: 2,
          lg: 1,
        },
        col2: {
          xs: 8,
          sm: 10,
          lg: 11,
        },
      });
    }
    setLogoShow(true);
  };

  return (
    <div
      id="container-layout-with-head"
      style={{
        visibility: logoShow ? "visible" : "hidden",
      }}
    >
      <Layout
        onClickToggle={props_onclickToggle}
        allowAnonymous={props_allowAnonymous}
      >
        <div
          style={{
            backgroundColor: theme.spo.product.backgroundPage,
            paddingBottom: theme.spo.pSm,
          }}
        >
          {/* container testata */}
          <Grid
            container
            p={3}
            style={{
              backgroundColor: theme.spo.product.backgroundPaper,
              //   paddingLeft: theme.spo.pSm,
              //   paddingTop: theme.spo.pXs,
              //   paddingRight: theme.spo.pSm,
            }}
          >
            {/* riga uno logo / search */}
            <Grid item xs={12} style={theme.spo.product.mainHeader}>
              <Grid
                container
                style={{
                  //padding: theme.spo.mSm,
                  // alignItems: "center",
                  paddingLeft: 5,
                }}
              >
                <Grid item style={{}} {...colRowLogo.col1}>
                  {/* {props_rowLogoLeft} */}
                  <Logo getSizeOfLogo={getSizeOfLogo}></Logo>
                </Grid>
                <Grid item {...colRowLogo.col2}>
                  {props_rowLogoRight}
                </Grid>
              </Grid>
            </Grid>
            {/* riga 2 menu */}
            <Grid item xs={12}>
              {props_rowMenuLeft}
              {props_rowMenuRight}
            </Grid>
            {/* riga 3 department */}
            {/* <Grid item xs={12}> */}
            <Grid
              item
              xs={12}
              style={{
                marginTop: theme.spo.mSm,
                marginBottom: theme.spo.mSm,
                marginLeft: theme.spo.mSm,
              }}
            >
              <Grid container>{props_rowBar}</Grid>
            </Grid>
          </Grid>
          {/* main page */}
          <Grid
            container
            style={{
              marginTop: theme.spo.mSm,
            }}
          >
            {props.children}
          </Grid>
        </div>
      </Layout>
    </div>
  );
};

export default LayoutWithHead;
