import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { roundTwoDigit } from "../../utility/utility";

import { Box, ButtonGroup, Button, Tooltip } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

const ProductCounter = (props) => {
  const props_readOnly = props.readOnly;
  const props_product = props.product;
  const props_setCount = props.setCount;
  const props_count = props.count;
  const props_removeProduct = props.removeProduct;
  const props_updateQuantity = props.updateQuantity;
  const props_allowZero = props.allowZero;

  const theme = useTheme();

  useEffect(() => {
    if (!props_count || !props_product) return;
    if (props_product.qtaMax > 0 && props_count > props_product.qtaMax) {
      props_setCount(props_product.qtaMax);
      NotificationManager.warning(
        "Quantità massima del prodotto " +
          props_product.description +
          " è cambiata a" +
          props_product.qtaMax +
          " " +
          props_product.unmis
      );
      props_updateQuantity(props_product, props_product.qtaMax);
    }
  }, [props_count, props_product]);

  return (
    <div>
      {props_product && (
        <>
          <Box
            id={"product-counter-" + props_product.coD_RIORD}
            style={{
              //paddingRight: theme.spo.mSm,
              fontSize: "10px",
              height: "14px",
            }}
          >
            {props_product.qtaMax
              ? "Max " + props_product.qtaMax + " " + props_product.unmis
              : ""}
          </Box>
          <ButtonGroup size="small" aria-label="small outlined button group">
            {props_readOnly == false && (
              <Tooltip title={"Diminuisci quantità"}>
                <Button
                  onClick={() => {
                    if (!props_allowZero && props_count == 1) {
                      return;
                    }
                    let c = roundTwoDigit(props_count - props_product.qtaBase);
                    props_setCount(c);
                    if (c <= 0) {
                      props_removeProduct(props_product);
                      return;
                    }
                    props_updateQuantity(props_product, c);
                  }}
                >
                  -
                </Button>
              </Tooltip>
            )}

            <Button disabled>
              {roundTwoDigit(props_count)}{" "}
              {props_product && props_product.unmis}
            </Button>
            {props_readOnly == false && (
              <Tooltip title={"Aumenta quantità"}>
                <Button
                  onClick={() => {
                    let q = props_count + props_product.qtaBase;
                    if (props_product.qtaMax && q > props_product.qtaMax) {
                      NotificationManager.warning(
                        "Hai raggiunto la quantità massima acquistabile per questo prodotto"
                      );
                      return;
                    }
                    let c = roundTwoDigit(q);
                    props_setCount(c);
                    props_updateQuantity(props_product, c);
                  }}
                >
                  +
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        </>
      )}
    </div>
  );
};

export default ProductCounter;
