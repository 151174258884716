import React, { useState, useEffect } from "react";

import { GET, POST, PUT, DELETE } from "request-helper";

import {
  Button,
  Grid,
  Paper,
  Divider,
  Link,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";

import DialogWithContent from "./DialogWithContent";
import { MessageRounded } from "@material-ui/icons";

const DialogMessages = (props) => {
  const props_messages = props.messages;
  const props_onClose = props.onClose;
  const props_requestRegistered = props.requestRegistered;
  //   const props_messages = [
  //     { id: 99, title: "asdasd", message: "asdadas", allowNotShowAgain: true },
  //     { id: 100, title: "asdasd", message: "asdadas", allowNotShowAgain: true },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //     { title: "asdasd", message: "asdadas" },
  //   ];

  const [showDialog, setShowDialog] = useState(false);
  const [close, setClose] = useState(false);

  const [messagesState, setMessagesState] = useState([]);

  const fetcNotShowAgain = (idMessages) => {
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Messages/SetMessagesReaded",
      { IdMessages: idMessages },
      null,
      true
    ).then((data) => {});
  };

  const filterMessageShowed = (data) => {
    let messages = [...data];
    if (!messages && !messages.length) return;

    let readedMessage = JSON.parse(localStorage.getItem("messages")) || [];
    if (props_requestRegistered === true) {
      //tolgo dall'array locale dei messaggi letti quelli che non sono + visibili
      readedMessage = readedMessage.filter(
        (id) => messages.filter((c) => id == c.id).length > 0
      );
      localStorage.setItem("messages", JSON.stringify(readedMessage));
    }

    let res = messages.filter((msg) => {
      if (msg.allowNotShowAgain && readedMessage && readedMessage.length) {
        let isReaded = readedMessage.filter((c) => msg === c.id).length > 0;
        return isReaded;
      }
      return true;
    });

    setMessagesState(res);
    if (res.length > 0) setShowDialog(true);

    /*
      allowNotShowAgain: true
      dateTimeEnd: null
      dateTimeStart: null
      id: 1
      message: "prova1"
      shopId: nul
      title: "prova"
      userRegistered: false
    */
  };

  const closeDialog = () => {
    if (props_onClose) {
      props_onClose();
    }
    setShowDialog(false);
    setClose(true);
    let idNotShow = messagesState
      .filter((c) => c.notShow === true)
      .map((c) => c.id);
    if (props_requestRegistered === false) {
      let readedMessage = JSON.parse(localStorage.getItem("messages")) || [];
      // let r = [...readedMessage, ...idNotShow];
      let r = [].concat(readedMessage, idNotShow);
      localStorage.setItem("messages", JSON.stringify(r));
    } else {
      fetcNotShowAgain(idNotShow);
    }
  };

  useEffect(() => {
    if (!props_messages || close === true) {
      return;
    }
    if (props_messages.length === 0) return;
    filterMessageShowed(props_messages);
  }, [props_messages]);
  return (
    <>
      <DialogWithContent
        key={"dl-privacy"}
        open={showDialog}
        onClose={() => {}}
        maxWidth={"md"}
        fullWidth={true}
        contentText={
          <div style={{ height: "250px" }}>
            <div>Hai ricevuto dei messaggi</div>
            <List component="nav" aria-label="main mailbox folders">
              {messagesState &&
                messagesState.length > 0 &&
                messagesState.map &&
                messagesState.map((item) => {
                  return (
                    <ListItem style={{ borderBottom: "1px solid #ccc" }}>
                      {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
                      {/* <ListItemText
                        primary={
                          <>
                            <div style={{ fontWeight: "bold" }}>
                              {item.title}
                            </div>
                            <div>{item.message}</div>
                          </>
                        }
                      /> */}
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                          }}
                        >
                          <DraftsIcon style={{ fontSize: "3.5em" }} />
                        </Grid>
                        <Grid item xs={11}>
                          <div style={{ fontWeight: "bold" }}>{item.title}</div>
                          <div>{item.message}</div>
                        </Grid>
                        <Grid item xs={12}>
                          {item.allowNotShowAgain && (
                            <FormControlLabel
                              disable={true}
                              control={
                                <Checkbox
                                  // disabled={!item.allowNotShowAgain}
                                  checked={item.notShow}
                                  onChange={(event) => {
                                    item.notShow = event.target.checked;
                                  }}
                                  name={"notShowAgain"}
                                />
                              }
                              label={"Non visualizzare più"}
                            />
                          )}
                          {!item.allowNotShowAgain && <div>&nbsp;</div>}
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              <ListItem button onClick={closeDialog}>
                <ListItemText
                  primary={"Chiudi"}
                  style={{ textAlign: "center" }}
                />
              </ListItem>
            </List>
          </div>
        }
        title="Messaggi"
        action={[]}
      ></DialogWithContent>
    </>
  );
};

export default DialogMessages;
