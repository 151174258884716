import { SET_APP_CFG, SET_LANGUAGE } from "../actionTypes";

const initialState = {
  language: "it",
  //translation: window.translation["it"],
  cfg: {
    role: {
      roleUser: { role: "user", weight: 1 },
      roles: [
        { role: "user", weight: 1 },
        { role: "admin", weight: 125 },
        { role: "sa", weight: 255 },
      ],
    },
  },
};

const AppCfgReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_CFG: {
      state = { ...state, ...{ cfg: action.payload } };
    }

    case SET_LANGUAGE: {
      var transl = { ...window.translation[action.payload] };
      if (process.env.REACT_APP_CUSTOMER) {
        transl = {
          ...window.translation[action.payload],
          ...window.translation[action.payload][process.env.REACT_APP_CUSTOMER],
        };
      }
      state = {
        ...state,
        ...{
          language: action.payload,
          translation: transl,
        },
      };
      return state;
    }
    default: {
      return state;
    }
  }
};
export default AppCfgReducers;
