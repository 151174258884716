import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Box,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Button,
  Tooltip,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCartPlus } from "@fortawesome/free-solid-svg-icons";
import DialogConfirm from "../DialogConfirm";

const ContentLeftBar = (props) => {
  const theme = useTheme();
  const props_idCategory = props.idCategory;
  const props_brand = props.brand;
  const props_setBrand = props.setBrand;
  const props_listBrand = props.listBrand;
  const props_listCategory = props.listCategory;
  const props_onChangeSelectCategory = props.onChangeSelectCategory;

  const props_current = props.current;

  const props_listUser = props.listUser || {};
  const props_setListUser = props.setListUser;
  const props_onClickNewList = props.onClickNewList || function () {};
  const props_onClickDelList = props.onClickDelList || function () {};
  const props_onClickToCartList = props.onClickToCartList || function () {};

  const [model, setModel] = useState({
    selectedShopList: "",
  });

  useEffect(() => {
    if (!props_listUser.selected) return;
    setModel({ ...model, selectedShopList: props_listUser.selected });
  }, [props_listUser.selected]);

  return (
    <Box id="content-left-bar" style={{ padding: theme.spo.pSm }}>
      {props_current == 4 && (
        <div>
          <Button onClick={props_onClickNewList} variant="contained">
            Nuova Lista della spesa
          </Button>
        </div>
      )}
      {props_current == 4 &&
        props_listUser &&
        props_listUser.list &&
        props_listUser.list.map && (
          <Box
            key={"list-brand-box" + Math.floor(Math.random() * 999)}
            style={{
              paddingTop: theme.spo.mMd,
            }}
          >
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <FormLabel component="legend">Le tue liste della spesa</FormLabel>
              <RadioGroup
                value={model.selectedShopList}
                onChange={(event) => {
                  setModel({ ...model, selectedShopList: event.target.value });
                  props_setListUser({
                    ...props_listUser,
                    selected: event.target.value,
                  });
                }}
              >
                <Box
                  style={{
                    paddingTop: theme.spo.mMd,
                    height: "450px",
                    overflow: "auto",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        selected={!props_listUser.selected}
                        name={"none"}
                        value={""}
                      />
                    }
                    label={<span style={{ fontSize: "12px" }}>Nessuna</span>}
                  />
                  {props_listUser.list.map((c) => {
                    return (
                      <div key={"list-listUser-check" + c.id}>
                        <Grid container>
                          <Grid item xs={7}>
                            <FormControlLabel
                              control={
                                <Radio
                                  selected={
                                    props_listUser.selected &&
                                    props_listUser.selected == c.id
                                  }
                                  name={c.id}
                                  value={c.id}
                                />
                              }
                              label={
                                <span style={{ fontSize: "12px" }}>
                                  {c.description}
                                </span>
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <DialogConfirm
                              title={"Elimina"}
                              text={
                                "Sei sicuro di voler eliminare questa lista?"
                              }
                            >
                              {(confirm) => (
                                <Tooltip title={"Elimina la lista"}>
                                  <IconButton
                                    color="primary"
                                    onClick={confirm(() => {
                                      props_onClickDelList(c.id);
                                    })}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </DialogConfirm>
                          </Grid>
                          <Grid item xs={3}>
                            <Tooltip title={"Aggiungi la lista al carrello"}>
                              <IconButton
                                disabled={
                                  !(
                                    props_listUser.selected &&
                                    props_listUser.selected == c.id
                                  )
                                }
                                onClick={() => {
                                  props_onClickToCartList(c.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faCartPlus} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </Box>
              </RadioGroup>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
          </Box>
        )}

      {props_listCategory ? (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="category-select-label">Categoria</InputLabel>
          <Select
            // className={}
            labelId="category-select-label"
            id="category-select"
            value={props_idCategory}
            onChange={props_onChangeSelectCategory}
          >
            {props_listCategory &&
              props_listCategory.map &&
              props_listCategory.map((c) => {
                return (
                  <MenuItem
                    id={"list-cat-" + c.idCatWebS}
                    key={"list-cat-" + c.idCatWebS}
                    value={c.idCatWeb}
                  >
                    {c.desCatWeb}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
      ) : (
        "Nessun reparto selezionato"
      )}
      {props_listBrand && props_listBrand.map && (
        <Box
          key={"list-brand-box" + Math.floor(Math.random() * 999)}
          style={{
            paddingTop: theme.spo.mMd,
          }}
        >
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Marche</FormLabel>
            <FormGroup>
              <Box
                style={{
                  paddingTop: theme.spo.mMd,
                  height: "450px",
                  overflow: "auto",
                }}
              >
                {props_listBrand &&
                  props_listBrand.map &&
                  props_listBrand.map((c) => {
                    return (
                      <div key={"list-brand-check" + c.idBrand}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={props_brand[c.idBrand]}
                              onChange={(event) => {
                                props_setBrand({
                                  ...props_brand,
                                  [event.target.name]: event.target.checked,
                                });
                              }}
                              name={c.idBrand}
                            />
                          }
                          label={c.brandDescription}
                        />
                      </div>
                    );
                  })}
              </Box>
            </FormGroup>
            {/* <FormHelperText>Be careful</FormHelperText> */}
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default ContentLeftBar;
