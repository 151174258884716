var classCallCheck = function classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

function _calculateUrl(controller, version) {
  var url = //lasciato REACT_APP_URL_API_APP_USER_MANAGER solo per compatibilità verificare OBSOLETO da togliere
  (process.env.REACT_APP_URL_API || process.env.REACT_APP_URL_API_APP_USER_MANAGER) + version + "/" + controller + "/";
  return url;
}

function _getDefaultConfig() {
  return singletonInstance.get();
}

function _setdefaultConfig(config) {
  singletonInstance.set(config);
}

function _onStartLoading(showLoader) {
  //if (!showLoader) return;
  if (singletonCounterRequest.opened === 0 && singletonCounterRequest.onStartLoading) singletonCounterRequest.onStartLoading(showLoader);
}

function _onEndLoading(showError) {
  //if (!showError) return;
  if (singletonCounterRequest.opened === 0 && singletonCounterRequest.onEndLoading) singletonCounterRequest.onEndLoading();
}

function _makeFetch(url, method, body, headers, showLoader, showError) {
  var defaultConfig = _getDefaultConfig();

  var _headers = defaultConfig.headers ? _extends({}, defaultConfig.headers, headers) : headers;

  var options = {
    method: method,
    headers: _headers
  };
  if (body) options.body = JSON.stringify(body);

  _onStartLoading(showLoader);

  singletonCounterRequest.opened += 1;
  var promise = null;

  try {
    promise = fetch(url, options).then(function (response) {
      switch (response.status.toString()) {
        case "401":
          if (showError) defaultConfig.handler.unAuthorized(response);
          return '{"error": "401 unauth" }';

        case "400":
          if (showError) defaultConfig.handler.fail(response);
          return '{ "error": "400 badrequest" }';

        case "404":
          if (showError) defaultConfig.handler.fail(response);
          return '{"error": "404 notfound" }';

        case "500":
          if (showError) defaultConfig.handler.fail(response);
      }

      singletonCounterRequest.opened -= 1;

      _onEndLoading(showLoader); // return response.json();


      return response.text();
    }).then(function (text) {
      if (!text.length) return {};
      var j = null;

      try {
        j = JSON.parse(text);
      } catch (e) {
        return text;
      }

      if (j) {
        var err = j.error ? j.error.message ? {
          Code: "generic",
          Message: j.error.message
        } : {
          Code: "generic",
          Message: j.error
        } : j.errors ? j.errors.message ? {
          Code: "generic",
          Message: j.errors.message
        } : {
          Code: "generic",
          Message: j.errors
        } : j.Error ? {
          Code: "generic",
          Message: j.Error
        } : j.ExceptionDetail ? j.ExceptionDetail : null;

        if (err) {
          j = _extends({}, j, {
            ex: err,
            hasError: true
          });
          console.error("errore nella chiamata Fetch :" + JSON.stringify(err));
        }
      }

      if (j.error) throw j;
      return j; // return text.length ? JSON.parse(text) : {};
    }).catch(function (error) {
      singletonCounterRequest.opened -= 1;

      _onEndLoading();

      if (showError) defaultConfig.handler.fail(error);
      return error;
    });
  } catch (ex) {
    singletonCounterRequest.opened -= 1;

    _onEndLoading(showLoader);

    if (showError) defaultConfig.handler.fail(ex);
  }

  return promise;
}

var DefaultsConfig = function DefaultsConfig() {
  classCallCheck(this, DefaultsConfig);
  var defaultsConfig = {
    headers: {},
    handler: {
      success: function success() {},
      fail: function fail() {},
      unAuthorized: function unAuthorized() {}
    }
  };

  this.get = function () {
    if (!window.fetchDefaultsConfig) window.fetchDefaultsConfig = defaultsConfig;
    return window.fetchDefaultsConfig;
  };

  this.set = function (cfg) {
    window.fetchDefaultsConfig = cfg;
  };
};

var singletonInstance = new DefaultsConfig(); //Object.freeze(singletonInstance);

function getDefaultConfig() {
  return _getDefaultConfig();
}

var statusRequest = function statusRequest() {
  classCallCheck(this, statusRequest);
  this.opened = 0;

  this.onStartLoading = function () {};

  this.onEndLoading = function () {};
}
/**
 * numero di chiamate aperte
 */

/**
 * handler chiamato per l'avvia di almeno una chiamata rest.
 */

/**
 * handler chiamato la fine di tutte le chiamate rest partite , se viene chiamato questo metodo , non ci sono altre chiamate in attesa
 */
;

var singletonCounterRequest = new statusRequest();
/**
 * ottiene un oggetto per la gestione delle chiamate , eventi start end  etc
 */

function getCounterRequest() {
  return singletonCounterRequest;
}

function getBaseUrl(controller, version) {
  return _calculateUrl(controller, version);
}

function setBearerToken(token) {
  var cfg = _getDefaultConfig();

  cfg.headers["Authorization"] = "Bearer " + token;

  _setdefaultConfig(cfg);
}

function addHeaders(objectHeaders) {
  var cfg = _getDefaultConfig();

  cfg.headers = _extends({}, cfg.headers, objectHeaders);

  _setdefaultConfig(cfg);
}

function addDefaultSucces(successHandler) {
  var cfg = _getDefaultConfig();

  cfg.handler = _extends({}, cfg.handler, {
    success: successHandler
  });

  _setdefaultConfig(cfg);
}

function addDefaultFail(failHandler) {
  var cfg = _getDefaultConfig();

  cfg.handler = _extends({}, cfg.handler, {
    fail: failHandler
  });

  _setdefaultConfig(cfg);
}

function addDefaultUnAuthorized(unAuthHandler) {
  var cfg = _getDefaultConfig();

  cfg.handler = _extends({}, cfg.handler, {
    unAuthorized: unAuthHandler
  });

  _setdefaultConfig(cfg);
}
/** params =  oggetto contenente i parametri della querystring
 *  showLoader se false , la chiamata non viene censita nella gestione dei counter, e non viene calcolata dagli eventi
 * onStartLoading , onEndLoading .
 * */


function GET(url, headers, params, showLoader, showError) {
  var _showLoader = showLoader === undefined ? true : showLoader;

  var _showError = showError === undefined ? true : showError;

  var _url = url;

  if (params) {
    var paramsArray = [];

    for (var key in params) {
      var v = params[key];
      if (v == null) v = "";
      if (v instanceof Date) v = v.toISOString();
      paramsArray.push(key + "=" + v.toString());
    }

    _url += "?" + paramsArray.join("&");
  }

  return _makeFetch(_url, "GET", null, headers, _showLoader, _showError);
}
/**
 *  showLoader se false , la chiamata non viene censita nella gestione dei counter, e non viene calcolata dagli eventi
 * onStartLoading , onEndLoading .
 * */


function PUT(url, body, headers, showLoader) {
  var _showLoader = showLoader === undefined ? true : showLoader;

  return _makeFetch(url, "PUT", body, headers, _showLoader);
}

function POST(url, body, headers, showLoader) {
  var _showLoader = showLoader === undefined ? true : showLoader;

  return _makeFetch(url, "POST", body, headers, _showLoader);
}

function DELETE(url, headers, showLoader) {
  var _showLoader = showLoader === undefined ? true : showLoader;

  return _makeFetch(url, "DELETE", null, headers, _showLoader);
}

function List(controller, version) {
  return GET(_calculateUrl(controller, version) + "List", null);
}

function GET1(_ref) {
  var url = _ref.url,
      params = _ref.params,
      headers = _ref.headers,
      showLoader = _ref.showLoader,
      showError = _ref.showError;

  var _showLoader = showLoader === undefined ? true : showLoader;

  var _showError = showError === undefined ? true : showError;

  return GET(url, headers, params, _showLoader, _showError);
}

function PUT1(_ref2) {
  var url = _ref2.url,
      body = _ref2.body,
      headers = _ref2.headers,
      showLoader = _ref2.showLoader,
      showError = _ref2.showError;

  var _showLoader = showLoader === undefined ? true : showLoader;

  var _showError = showError === undefined ? true : showError;

  return _makeFetch(url, "PUT", body, headers, _showLoader, _showError);
}

function POST1(_ref3) {
  var url = _ref3.url,
      body = _ref3.body,
      headers = _ref3.headers,
      showLoader = _ref3.showLoader,
      showError = _ref3.showError;

  var _showLoader = showLoader === undefined ? true : showLoader;

  var _showError = showError === undefined ? true : showError;

  return _makeFetch(url, "POST", body, headers, _showLoader, _showError);
}

function DELETE1(_ref4) {
  var url = _ref4.url,
      body = _ref4.body,
      headers = _ref4.headers,
      showLoader = _ref4.showLoader,
      showError = _ref4.showError;

  var _showLoader = showLoader === undefined ? true : showLoader;

  var _showError = showError === undefined ? true : showError;

  return _makeFetch(url, "DELETE", body, headers, _showLoader, _showError);
}

function List1(controller, version) {
  return GET(_calculateUrl(controller, version) + "List", null);
}

export { getDefaultConfig, getCounterRequest, getBaseUrl, setBearerToken, addHeaders, addDefaultSucces, addDefaultFail, addDefaultUnAuthorized, GET, PUT, POST, DELETE, List, GET1, PUT1, POST1, DELETE1, List1 };