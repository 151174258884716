import React, { useState, useEffect } from "react";

import DialogWithContent from "..//DialogWithContent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUndo } from "@fortawesome/free-solid-svg-icons";

import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
const DialogListShopList = (props) => {
  const props_showState = props.showState;
  const props_setShowState = props.setShowState;
  const props_elementList = props.elementList;
  const props_confirm = props.confirm;
  const props_undo = props.undo;
  const props_hideNew = props.hideNew;

  const props_helperTextSelect = props.helperTextSelect;
  const props_helperText = props.helperText;

  const [model, setModel] = React.useState({
    selectedNewList: "",
    selectedNewListUpdate: true,
    helperValidationTextField: null,
  });

  return (
    <DialogWithContent
      open={props_showState}
      onClose={() => {
        props_setShowState(false);
      }}
      contentText={"Quale lista della spesa vuoi usare?"}
      content={
        <>
          <Grid container>
            <Grid item container xs={12}>
              {model.selectedNewListUpdate &&
                props_elementList &&
                props_elementList.length > 0 && (
                  <>
                    <Grid xs={10}>
                      <FormControl style={{ width: "100%" }}>
                        <Select
                          style={{ width: "100%" }}
                          value={model.selectedNewList}
                          onChange={(e) => {
                            setModel({
                              ...model,
                              selectedNewList: e.target.value,
                            });
                          }}
                        >
                          {props_elementList.map((el) => {
                            return (
                              <MenuItem value={el.id}>
                                {el.label || el.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {props_helperTextSelect}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {!props_hideNew && (
                      <Grid xs={2}>
                        <Button
                          onClick={(e) => {
                            setModel({
                              ...model,
                              selectedNewListUpdate: false,
                              selectedNewList: "",
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              {(!model.selectedNewListUpdate ||
                (props_elementList && props_elementList.length == 0)) && (
                <>
                  <Grid item xs={10}>
                    <TextField
                      style={{ width: "100%" }}
                      value={model.selectedNewList}
                      onChange={(e) => {
                        let v = e.target.value;
                        setModel({
                          ...model,
                          selectedNewList: e.target.value,
                        });
                      }}
                      helperText={
                        model.helperValidationTextField || props_helperText
                      }
                    ></TextField>
                  </Grid>
                  {props_elementList.length > 0 && (
                    <Grid item xs={2}>
                      <Button
                        onClick={(e) => {
                          setModel({
                            ...model,
                            selectedNewListUpdate: true,
                            selectedNewList: "",
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faUndo}></FontAwesomeIcon>
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </>
      }
      title="Salva in lista"
      action={[
        {
          onClick: () => {
            props_undo();
            setModel({
              ...model,
              selectedNewListUpdate: true,
              selectedNewList: "",
            });
            props_setShowState(false);
          },
          label: "Annulla",
        },
        {
          onClick: () => {
            if (
              model.selectedNewList === null ||
              model.selectedNewList === ""
            ) {
              setModel({
                ...model,
                helperValidationTextField: "Il campo non può essere vuoto",
              });
              return;
            }
            if (!props_hideNew && !model.selectedNewList.match(/[A-Za-z]/)) {
              setModel({
                ...model,
                helperValidationTextField:
                  "Il campo deve contenere almeno una lettera",
              });
              return;
            }
            var r = props_confirm(model.selectedNewList);
            if (!r) return;
          },
          label: "Ok",
        },
      ]}
    ></DialogWithContent>
  );
};

export default DialogListShopList;
