import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import { roundTwoDigit } from "../../utility/utility";
import ProductCounter from "../Product/ProductCounter";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme, Tool } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Tooltip } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faBookOpen,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { FormattedNumber } from "react-intl";

const CardProduct = (props) => {
  const theme = useTheme();

  const props_gridSize = props.gridSize || {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
    xl: 2,
  };
  const props_product = props.product;
  const props_cart = props.cart || {};

  const props_readOnly = props.readOnly || false;
  const props_noCounter =
    props.noCounter == null || props.noCounter == undefined
      ? false
      : props.noCounter;
  const props_noFavorite =
    props.noFavorite == null || props.noFavorite == undefined
      ? false
      : props.noFavorite;
  const props_overridePrice = props.overridePrice;
  const props_newProduct = props.newProduct || function () {};
  const props_removeProduct = props.removeProduct || function () {};
  const props_updateQuantity = props.updateQuantity || function () {};
  const props_setFavorite = props.setFavorite || function () {};
  const props_favorites = props.favorites;

  const props_setShopList = props.setShopList;
  const props_shopList = props.shopList;

  const props_currentIdArea = props.currentIdArea;

  const [count, setCount] = React.useState(0);
  const [countshopList, setCountShopList] = React.useState(0);

  const setCountFromArea = (data) => {
    switch (props_currentIdArea) {
      case 4:
        if (data) setCountShopList(data);
        return setCountShopList;
      default:
        if (data) setCount(data);
        return setCount;
    }
  };

  const countFromArea = () => {
    switch (props_currentIdArea) {
      case 4:
        return countshopList;
      default:
        return count;
    }
  };

  useEffect(() => {
    if (!props_product) return;
    // let isInShopList = isInShopListLocal(props_product);
    // setObjInShopList(isInShopList);
    if (
      props_cart.webSpoProductInCart &&
      props_cart.webSpoProductInCart.filter
    ) {
      setCountFromArea(0);
      setCount(0);
      setCountShopList(0);
      //sono nella sezione liste della spesa
      if (props_currentIdArea == 4) {
        let objInShopList = isInShopListLocal(props_product);
        if (objInShopList) {
          setCountFromArea(
            objInShopList.quantityInShopList
              ? objInShopList.quantityInShopList
              : objInShopList.qtaBase
          );
          return;
        }
      } else {
        let objInCart = props_cart.webSpoProductInCart.filter((c) => {
          return c.idProduct == props_product.coD_RIORD;
        });
        if (objInCart && objInCart[0]) {
          setCountFromArea(objInCart[0].quantity);
        }
      }

      return;
    }
  }, [props_product]);

  useEffect(() => {
    //08-11-21 commentato se il carrello è vuoto non ci sono schede product da visualizzare
    // if (
    //   props_cart &&
    //   props_cart.webSpoProductInCart &&
    //   props_cart.webSpoProductInCart.length == 0
    // )
    //   setCount(0);
  }, [props_cart]);

  const handleClickFavorite = () => {
    props_setFavorite(props_product);
  };

  const isFavoriteLocal = (product) => {
    if (!props_favorites) return false;
    let isfav = props_favorites.filter((c) => c.idProduct == product.coD_RIORD);
    return isfav[0] != undefined;
  };

  const isInShopListLocal = (product) => {
    if (!props_shopList) return false;
    let isfav = props_shopList.filter((c) => c.coD_RIORD == product.coD_RIORD);
    return isfav[0] ? product : null;
    //return product.quantityInShopList ? product : null;
  };

  const handleClickShopList = () => {
    props_setShopList(props_product, countFromArea());
  };

  return (
    <Grid
      id={"card-product-grid" + props_product.coD_RIORD}
      item
      {...props_gridSize}
    >
      {/* style={{ margin: theme.spo.mSm }}> */}
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: "10px", height: "310px" }}>
            {/* <Paper style={{ padding: theme.spo.mSm, height: "320px" }}> */}
            <Grid container>
              {/* <div style={{ height: "120px", width: "100%" }}> */}
              <div style={{ height: "100px", width: "100%" }}>
                <Grid item xs={12}>
                  <div style={{ textAlign: "right" }}>
                    <Tooltip title={"Aggiungi alla lista della spesa"}>
                      <IconButton
                        color="primary"
                        onClick={handleClickShopList}
                        style={{ padding: 0 }}
                      >
                        {isInShopListLocal(props_product) ? (
                          <FontAwesomeIcon icon={faBookOpen} />
                        ) : (
                          <FontAwesomeIcon icon={faBook} />
                        )}
                      </IconButton>
                    </Tooltip>
                    {!props_noFavorite && (
                      <Tooltip title={"Aggiungi ai preferiti"}>
                        <IconButton
                          color="primary"
                          aria-label="Aggiungi ai preferiti"
                          onClick={handleClickFavorite}
                          style={{ padding: 0 }}
                        >
                          {isFavoriteLocal(props_product) ? (
                            //|| props_product.isFavorite
                            <FavoriteIcon></FavoriteIcon>
                          ) : (
                            <FavoriteBorderIcon></FavoriteBorderIcon>
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: theme.spo.product.card.sizeCategoryText,
                    color: theme.spo.product.card.colorCategory,
                  }}
                >
                  {props_product.category}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: theme.spo.product.card.sizeDescriptionText,
                    color: theme.spo.product.card.colorDescription,
                    fontWeight: "bold",
                  }}
                >
                  <Link
                    href="#"
                    onClick={() => {
                      props.history.push(
                        process.env.REACT_APP_BASE_PATH_ROUTE +
                          "/detail/" +
                          props_product.coD_RIORD
                      );
                    }}
                  >
                    {props_product.description}
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: theme.spo.product.card.sizeBrandText,
                    color: theme.spo.product.card.colorBrand,
                  }}
                >
                  {props_product.marca}
                </Grid>
              </div>
              <Grid
                container
                style={{
                  height: "130px",
                  width: "100%",
                  // marginTop: theme.spo.mSm,
                  marginTop: theme.spo.mXs,
                }}
              >
                <Grid item xs={3}>
                  {props_product.promoPrice > 0 && (
                    <div>
                      {/* <div style={{ fontSize: "14px" }}>Promozione</div> */}
                      <div
                        style={{
                          backgroundColor: "#ff0000",
                          color: "#ffffff",
                          textAlign: "center",
                          fontSize: "23px",
                        }}
                      >
                        -
                        {Math.round(
                          100 -
                            (100 * props_product.promoPrice) /
                              props_product.price
                        )}
                        %
                      </div>
                    </div>
                  )}{" "}
                </Grid>
                <Grid item xs={6}>
                  <div>
                    {props_product.immagine ? (
                      <img
                        style={{ width: "100%", maxHeight: "200px" }}
                        src={"data:image/png;base64," + props_product.immagine}
                      ></img>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          textAlign: "center",
                        }}
                      >
                        Immagine attualmente non disponibile
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                </Grid>
              </Grid>
              <div style={{ height: "80px", width: "100%" }}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      id={"card-product-grid-price" + props_product.coD_RIORD}
                      item
                      xs={6}
                    >
                      {props_product.promoPrice > 0 ? (
                        <div
                          style={{
                            textDecoration: "line-through",
                            color: "#ff0000",
                          }}
                        >
                          <FormattedNumber
                            value={roundTwoDigit(
                              props_overridePrice
                                ? props_overridePrice
                                : props_product.price
                            ).toFixed(2)}
                            style="currency"
                            currency="EUR"
                            currencySign="accounting"
                          />
                        </div>
                      ) : (
                        <div>&nbsp; </div>
                      )}
                      {/*prChiaroPeso se 0 non viene visualizzato i lprezzo chiaro */}
                      <Box style={{ fontSize: "11px", height: "14px" }}>
                        {props_product.prChiaroPeso != 0 && (
                          <div>
                            {/*se PrChiaroPeso != 0 
	                      2decimal arrotondamento
	                      Streing(Math.round(Price/PrChiaroPeso,2)) +  " "+ PrChiaroDes (string) */}
                            <FormattedNumber
                              value={roundTwoDigit(
                                (props_product.promoPrice ||
                                  props_product.price) /
                                  props_product.prChiaroPeso
                              )}
                              style="currency"
                              currency="EUR"
                              currencySign="accounting"
                            />{" "}
                            {props_product.prChiaroDes}
                          </div>
                        )}
                      </Box>
                      <div
                        style={{
                          fontSize: theme.spo.product.card.sizePriceText,
                          color: theme.spo.product.card.colorPrice,
                          fontWeight: "bold",
                        }}
                      >
                        {
                          <FormattedNumber
                            value={roundTwoDigit(
                              props_product.promoPrice || props_product.price
                            ).toFixed(2)}
                            style="currency"
                            currency="EUR"
                            currencySign="accounting"
                          />
                        }{" "}
                      </div>
                    </Grid>
                    <Grid
                      id={
                        "card-product-grid-product-counter-" +
                        props_product.coD_RIORD
                      }
                      item
                      xs={6}
                      style={{
                        textAlign: "right",
                        paddingTop: theme.spo.mSm,
                        paddingBottom: theme.spo.mSm,
                      }}
                    >
                      {countFromArea() <= 0 && props_readOnly == false && (
                        <Box>
                          <Box
                            style={{
                              paddingRight: theme.spo.mSm,
                              fontSize: "12px",
                              height: "14px",
                            }}
                          >
                            {props_product.unmis &&
                              props_product.unmis.toLowerCase() == "kg" &&
                              props_product.unmis}
                          </Box>
                          <IconButton
                            color="primary"
                            aria-label="add to shopping cart"
                            onClick={() => {
                              if (!props_noCounter) {
                                setCountFromArea(props_product.qtaBase);
                              }
                              props_newProduct(props_product);
                            }}
                          >
                            <FontAwesomeIcon icon={faCartPlus} />
                          </IconButton>
                        </Box>
                      )}
                      {countFromArea() > 0 && (
                        <ProductCounter
                          readOnly={props_readOnly}
                          product={props_product}
                          setCount={setCountFromArea()}
                          count={countFromArea()}
                          allowZero={props_currentIdArea == 4 ? false : true}
                          removeProduct={props_removeProduct}
                          updateQuantity={(product, count) => {
                            props_updateQuantity(
                              product,
                              count,
                              isInShopListLocal(props_product)
                            );
                          }}
                        ></ProductCounter>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(CardProduct);
