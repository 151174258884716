import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { fetchCfgSpo, SetInfoCart } from "../redux/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Paper, Grid } from "@material-ui/core";

import { getImageFromStoreWithCode } from "../utility/utility";

const useStyles = makeStyles((theme) => ({}));

const CustomIcon = (props) => {
  const propsIcon = props.icon;
  const propsDbCode = props.dbCode;
  const propsBreakpoints = props.breakpoints || {};
  const propsCenter = props.center;

  return (
    <div>
      {propsDbCode &&
      getImageFromStoreWithCode(propsDbCode, props.store, true) ? (
        <Grid container justify={props.center ? "center" : "inherit"}>
          <Grid item {...propsBreakpoints}>
            <img
              style={{
                width: "100%",
              }}
              src={getImageFromStoreWithCode(propsDbCode, props.store, true)}
            />
          </Grid>
        </Grid>
      ) : (
        propsIcon
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, {
  fetchCfgSpo,
})(CustomIcon);
